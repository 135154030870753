/* src/component/zNumberForm/ZNumberForm.css */

.zNumberForm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.zNumberForm-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  gap: 10px;
}
.zNumberForm-form2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  gap: 10px;
  padding-right: 26px;
}

.sub-zNumberForm-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 10px;
    margin-right: 10px; */
  gap: 10px;
}

/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .zNumberForm-form2 {
    display: flex;
    flex-direction: column;
  }
  .sub-zNumberForm-form {
    padding-right: 26px;
  }
  .zNumberForm-form2 {
    padding-right: 0px;
  }
}
.zNumberForm-form-title {
  margin-bottom: 20px;
}

.label {
  display: block;
  padding-inline: 20px;
  margin-bottom: 10px;
  font-size: calc(10px + 2vmin);
  color: #39d861;
  /* text-shadow: 0px 0px 3px #32c456; */
}

.label-zNumber {
  display: block;
  pointer-events: none;
  font-size: calc(24px + 2vmin);
  color: #39d861;
  /* text-shadow: 0px 0px 3px #32c456; */
}

.labelError {
  margin-top: 10px;
  display: block;
  font-size: calc(8px + 2vmin);
  color: #d88e39;
  margin-bottom: 0px;
}

.labelSuccess {
  margin-top: 10px;
  display: block;
  font-size: calc(8px + 2vmin);
  color: #ffff00;
  margin-bottom: 0px;
}
.space {
  margin-top: 10px;
  display: block;
  margin-bottom: 0px;
  font-size: calc(8px + 2vmin);
  color: #000000;
}

input {
  display: flex;
  width: 300px;
  padding: 8px;
  border: none;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}
/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) and (orientation: portrait) {
  input {
    width: 50vw;
  }
}

.active-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #32c456;
  color: white;
  border: none;
  cursor: pointer;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}

.inactive-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: gray;
  color: white;
  border: none;
  cursor: not-allowed;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}
