/* src/component/hordeByCountry/HordeByCountry.css */
.hordeByCountry-title {
  font-weight: bold;
  font-size: calc(8px + 1vmin);
  text-align: left;
  text-decoration: underline;
  margin-top: 10px;
  margin-left: 5px;
}

.table-container {
  position: relative;
  overflow-y: auto;
}
.hordeByCountry-toggle-button {
  background-color: #32c456;
  border: none;
  cursor: pointer;
}

.hordeByCountry-close-button {
  position: absolute;
  top: 8px;
  right: 6px;
  background-color: #000000;
  border: none;
  cursor: pointer;
}
.hordeByCountry-toggle-button-image {
  width: 19px;
  height: 19px;
}
.hordeByCountry-close-button-image {
  width: 10px;
  height: 10px;
}

.hordeByCountry-panel-text {
  position: fixed;
  top: 50px;
  right: 10px;
  width: 12vw;
  background-color: #000000;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px dashed #32c456;
  z-index: 10;
  color: #32c456
}
/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .hordeByCountry-panel-text {
    width: 50vw;
  }

  .table-container {
    max-height: 75vh;
  }
}
/* Styles pour les petits écrans en orientation landscape */
@media (max-width: 767px) and (orientation: landscape) {
  .hordeByCountry-panel-text {
    width: 30vw;
  }
  .table-container {
    max-height: 60vh;
  }
}

/* Styles pour les petits écrans en orientation portrait */
@media (min-width: 768px) and (orientation: portrait) {
  .hordeByCountry-panel-text {
    width: 40vw;
  }
  .table-container {
    max-height: 75vh;
  }
}
/* Styles pour les petits écrans en orientation landscape */
@media (min-width: 768px) and (orientation: landscape) {
  .hordeByCountry-panel-text {
    width: 30vw;
  }
  .table-container {
    max-height: 60vh;
  }
}
.custom-table {
  width: 100%;
  /* border-collapse: collapse; */
  /* border: 1px solid #ddd; */
}

.custom-table th,
.custom-table td {
  padding: 10px;
  /* border: 1px solid #ddd; */
}

.custom-table th {
  text-align: center;
  position: relative; /* Ajouter une position relative pour la ligne en pointillés */
  border-top: 1px dashed #32c456; /* Style de la ligne en pointillés */
}

.custom-table th::after {
  content: "";
  position: absolute;
  bottom: -1px; /* Ajustez la position verticale de la ligne en pointillés */
  left: 0;
  width: 100%;
  height: 1px; /* Ajustez l'épaisseur de la ligne en pointillés */
}

.centered-cell {
  text-align: center;
  border-top: 1px dashed #32c456; /* Style de la ligne en pointillés */
}
.custom-table thead {
  position: sticky; /* Permet à l'en-tête de rester fixe */
  top: 0; /* Fixer l'en-tête en haut */
  background-color: #000000;
}
/* Ajoutez d'autres styles selon vos préférences */
