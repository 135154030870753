/* src/component/sound/Sound.css */
.sound-button {
  background-color: #000000;
  border: none;
  cursor: pointer;
  align-content: center;
  justify-content: center;
}

.sound-button-image-active {
  width: 20px;
  height: 20px;
}

.sound-button-image-inactive {
  width: 20px;
  height: 20px;
}
