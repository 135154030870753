/* src/component/signup/Signup.css */

.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.signup-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  gap: 10px;
}

/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .signup-form {
    display: flex;
    flex-direction: column;
  }
}
.signup-form-title {
  margin-bottom: 15px;
}

label {
  display: block;
  padding-inline: 20px;
  margin-bottom: 10px;
  font-size: calc(10px + 2vmin);
  color: #39d861;
  /* text-shadow: 0px 0px 3px #32c456; */
}

.label-subtitle {
  display: block;
  padding-inline: 20px;
  margin-bottom: 20px;
  font-size: calc(7px + 2vmin);
  color: #39d861;
  /* text-shadow: 0px 0px 3px #32c456; */
}

.labelError {
  margin-top: 10px;
  display: block;
  font-size: calc(8px + 2vmin);
  color: #d88e39;
  margin-bottom: 0px;
}

.labelSuccess {
  margin-top: 10px;
  display: block;
  font-size: calc(8px + 2vmin);
  color: #ffff00;
  margin-bottom: 0px;
}
.space {
  margin-top: 10px;
  display: block;
  margin-bottom: 0px;
  font-size: calc(8px + 2vmin);
  color: #000000;
}

input {
  display: flex;
  padding: 8px;
  border: none;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}

.active-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #32c456;
  color: white;
  border: none;
  cursor: pointer;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}

.inactive-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: gray;
  color: white;
  border: none;
  cursor: not-allowed;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}
