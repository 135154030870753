/* src/component/title/Title.css */
.title {
  width: 100vw;
  padding-bottom: 25px;
}

.title-label {
  font-size: calc(40px + 2vmin);
  font-weight: bolder;
  color: #32c456;
  text-shadow: 0px 0px 5px #32c456;
}

.title-label-post {
  font-size: calc(30px + 2vmin);
  font-weight: bolder;
  color: #32c456;
  text-shadow: 0px 0px 5px #32c456;
  padding-bottom: 15px;
}

.label-counter-inline {
  display: block;
  font-size: calc(15px + 1vmin);
  color: #ffff00;
  padding-left: 30px;
  padding-right: 30px;
}

.label-counter {
  display: none;
  font-size: calc(15px + 1vmin);
  color: #ffff00;
  padding-left: 30px;
  padding-right: 30px;
}

.title-text {
  font-size: calc(20px + 2vmin);
  color: #32c456;
}

.title-timer {
  font-size: calc(20px + 2vmin);
  color: #ffff00;
  font-family: "Fixed Width", monospace;
}
.title-timer-elapsed {
  font-size: calc(12px + 1vmin);
  color: #ffff00;
  font-family: "Fixed Width", monospace;
}
@media (max-width: 767px) and (orientation: portrait) {
  .title-label {
    padding-left: 10px;
    padding-right: 10px;
    font-size: calc(34px + 1vmin);
  }
  .title-label-post {
    padding-left: 30px;
    padding-right: 30px;
    font-size: calc(34px + 1vmin);
  }
  .title-timer {
    font-size: calc(16px + 2vmin);
  }
  .title-text {
    padding-left: 10px;
    padding-right: 10px;
    font-size: calc(16px + 2vmin);
  }
  .label-counter-inline {
    display: none;
  }
  .label-counter {
    display: block;
  }
}

.app-button {
  text-align: center;
  border: 1px solid #32c456;
  color: #32c456;
  background-color: #000000;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  font-size: calc(10px + 2vmin);
  margin-bottom: 20px;
  font-family: "Fixed Width", monospace;
}
