.app,
.app-loading {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #000000;
  height: 100%;
}

.app-container {
  flex-direction: column;
  width: 100vw;
  margin-top: 0px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: start;
  text-align: center;
  background-color: #000000;
}
/* 
body {
  padding-top: 30px;
} */

body::-webkit-scrollbar {
  width: 4px; /* Largeur de la barre de défilement */
  background-color: #000000; /* Couleur de fond de la barre de défilement */
}

body::-webkit-scrollbar-thumb {
  background-color: #32c456; /* Couleur du curseur de la barre de défilement */
  border-radius: 0px; /* Bordure arrondie du curseur */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #32c456aa; /* Couleur du curseur au survol */
}

.account-delete-container {
  height: 100vh;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: start;
  text-align: center;
  color: #32c456;
  background-color: #000000;
}

.app-panel-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

.app-panel-world-container {
  width: 90vw;
  display: flex;
  flex-direction: row;
}

.left-panel-world-container {
  width: 23vw;
  display: flex;
  flex-direction: column;
}

.right-panel-world-container {
  width: 40vw;
  display: flex;
  flex-direction: column;
}

.bottom-panel-world-container {
  /* margin-top: 20px; */
  width: 90vw;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

@media (orientation: portrait) {
  .left-panel-world-container,
  .right-panel-world-container {
    display: none;
  }
  .bottom-panel-world-container {
    display: flex;
  }
}

@media (orientation: landscape) {
  .bottom-panel-world-container {
    display: none;
  }
  .left-panel-world-container {
    display: flex;
    width: 28vw;
  }
  .right-panel-world-container {
    display: flex;
    width: 28vw;
  }
}
@media (min-width: 768px) {
  .right-panel-world-container {
    width: 28vw;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px)  {
  .bottom-panel-world-container {
    display: flex;
  }
}
@media (max-width: 767px) {
  .bottom-panel-world-container {
    display: flex;
  }
  .right-panel-world-container {
    width: 20vw;
    margin: auto;
  }
}

.app-panel2-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}

@media (max-width: 767px) and (orientation: portrait) {
  .app-panel2-container {
    flex-direction: column;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .app-panel2-container {
    flex-direction: row;
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  .app-panel2-container {
    flex-direction: row;
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .app-panel2-container {
    flex-direction: row;
  }
}

.app-chart-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 10px; */
  gap: 10px;
}
.app-tables-container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;
}
.app-tables-left-content {
  width: 90vw;
}
.app-tables-right-content {
  width: 90vw;
}
.chart-container-left {
  border: 1px dashed #32c456;
  padding: 5px;
  flex: 1;
  margin-bottom: 20px;
}
.chart-container {
  border: 1px dashed #32c456;
  padding: 5px;
  flex: 1;
  /* margin-bottom: 10px; */
}

@media (max-width: 767px) and (orientation: landscape) {
  .app-chart-container {
    flex-direction: row;
  }
  .app-tables-container {
    flex-direction: row;
  }
  .chart-container {
    width: 40vw;
  }
  .chart-container-left {
    margin-bottom: 0px;
  }
  .app-tables-right-content {
    width: 90vw;
  }
  .app-tables-left-content {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {

  .app-chart-container {
    flex-direction: row;
  }
  .app-tables-container {
    flex-direction: row;
  }
  .chart-container {
    width: 40vw;
  }
  .chart-container-left {
    margin-bottom: 0px;
  }
  .app-tables-right-content {
    width: 90vw;
  }
  .app-tables-left-content {
    display: none;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
  .app-tables-right-content {
    width: 90vw;
  }
  .chart-container-left {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .app-panel-container {
    flex-direction: row;
  }
  .app-chart-container {
    flex-direction: row;
  }
  .app-tables-container {
    flex-direction: row;
  }
  .chart-container-left {
    margin-bottom: 0px;
  }
}

.twitter-share-button {
  text-align: center;
  border: 1px solid #32c456;
  color: #32c456;
  background-color: #000000;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-family: "Fixed Width", monospace;
}

.howToStart-button {
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #32c456;
  color: white;
  border: none;
  cursor: pointer;
  font-size: calc(4px + 2vmin);
  font-family: "Fixed Width", monospace;
}

.panel-world-container {
  width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.left-world-container {
  width: 25%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
  padding-right: 20px;
}
.right-world-container {
  width: 25%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 20px;
}

.left-world-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;

  color: #32c456;
}

.left-world-content-country {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 20px;
}

@media (max-width: 767px) and (orientation: portrait) {
  .left-world-container {
    display: none;
  }

  .right-world-container {
    display: none;
  }
  .twitter-share-button {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .panel-world-container {
    align-items: center;
  }

  .left-world-container {
    width: 60%;
  }

  .right-world-container {
    display: none;
  }
  .left-world-content-country {
    display: none;
  }
  .twitter-share-button {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .left-world-container {
    display: none;
  }

  .right-world-container {
    display: none;
  }
  .twitter-share-button {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .panel-world-container {
    align-items: center;
  }

  .left-world-container {
    width: 60%;
  }

  .right-world-container {
    display: none;
  }
  .left-world-content-country {
    display: none;
  }
  .twitter-share-button {
    margin-bottom: 30px;
  }
}

@media (min-width: 1024px) and (orientation: portrait) {
  .left-world-container {
    display: none;
  }

  .right-world-container {
    display: none;
  }
}

@media (min-width: 1024px) and (orientation: landscape) {
}

.right-world-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.right-world-chart {
  border: 1px dashed #32c456;
  flex: 1;
  padding: 5px;
}
