/* src/component/world/World.css */
.title-globe {
    font-size: calc(12px + 1vmin);
  }
  .date-globe {
    color: #32c456;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2px;
    font-size: calc(1px + 2vmin);
    font-style: italic;
  }