/* src/component/header/Header.css */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  background-color: #32c456;
  box-shadow: 0px 0px 15px #32c456;
  z-index: 100;
  padding-top: 2px;
  padding-bottom: 2px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header,
.header-left,
.header-right,
.header-container {
  height: 30px;
}

.header-left {
  width: 70vw;
  justify-content: left;
}

.header-right {
  width: 30vw;
  align-content: right;
  justify-content: right;
}

.header-right-container {
  display: flex;
  width: 30vw;
  padding-top: 1px;
  align-content: right;
  justify-content: right;
}

@media (max-width: 767px) {
  .header-right-container {
    padding-right: 5px;
    margin-top: 3px;
  }
}

@media (min-width: 768px) {
  .header-right-container {
    padding-right: 10px;
  }
}

.header-label {
  text-align: center;
  color: #000000;
  font-size: medium;
  padding-left: 10px;
  margin-left: 10px;
}

.header-label-post {
  text-align: center;
  color: #000000;
  font-size: small;
  padding-left: 10px;
}

.header-label-post-sub {
  color: #000000;
  font-size: large;
  font-variant-position: sub;
  pointer-events: none;
}

.header-logo-a {
  width: 20px;
  height: 20px;
}

.header-logo {
  margin-left: 10px;
  pointer-events: none;
  animation-name: header-logo-spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 20px;
  height: 20px;
}

@keyframes header-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-button {
  background: none;
  border: none;
  cursor: pointer;
}
.header-button-x-twitter {
  background: none;
  border: none;
  cursor: pointer;
}

.header-button-image {
  width: 20px;
  height: 20px;
}

.header-button-image-x-twitter {
  width: 20px;
  height: 20px;
}
