/* src/component/footer/Footer.css */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  background-color: #000000;
  /* box-shadow: 0px 0px 5px #32c456; */
  z-index: 10;
  padding-top: 4px;
  padding-bottom: 2px;
}

.footer-container {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  margin-right: 20px;
}

.footer-label {
  display: block;
  color: #32c456;
  font-size: calc(5px + 1vmin);
}

/* Styles pour les grands écrans en orientation paysage */
@media (min-width: 768px) {
}
/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) {
  .footer-label {
    margin-top: 4px;
    font-size: calc(5px + 1vmin);
  }
}

/* Styles pour les grands écrans en orientation paysage */
@media (min-width: 768px) {
  .footer-label {
    padding-right: 5px;
    font-size: calc(8px + 1vmin);
  }
}

.footer-left,
.footer-right {
  display: flex;
}

.footer,
.footer-left,
.footer-right,
.footer-container {
  height: 30px;
}

.footer-left {
  width: 30vw;
  padding-left: 10px;
  justify-content: left;
}

.footer-right {
  width: 70vw;
  padding-right: 10px;
  padding-top: 6px;
  justify-content: right;
}
