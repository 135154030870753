/* src/component/chart/Chart.css */

.toggle-button-chart {
  /* background-color: #000000;
  border: none;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-top: 5px;
  margin-right: 2px; */
  
  position: fixed;
  top: 90px;
  left: 10px;
  width: 47px;
  flex-direction: row;
  background-color: #000000;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px dashed #32c456;
  z-index: 5;
}
.close-button-chart {
  position: absolute;
  top: 8px;
  right: 6px;
  background-color: #000000;
  border: none;
  cursor: pointer;
}
.toggle-button-image-chart {
  width: 18px;
  height: 18px;
}
.close-button-image-chart {
  width: 10px;
  height: 10px;
}

.panel-text-chart {
  position: fixed;
  top: 10vh;
  left: 10vw;
  width: 80vw;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border: 1px dashed #32c456;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  z-index: 100;
}
