/* src/component/leaderboard/Leaderboard.css */
.leaderboard-container {
  width: 100%;
  color: #32c456;
  /* margin-bottom: 30px; */
}

.leaderboard-content {
  width: 100%;
  color: #32c456;
  border-color: #32c456;
  border-width: 1px;
  border-style: dashed;
  align-self: center;
}

/* @media (max-width: 767px) and (orientation: portrait) {
  .leaderboard-container {
    display: none;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .leaderboard-container {
    display: none;
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  .leaderboard-container {
    display: none;
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .leaderboard-container {
    width: 27%;
  }
} */

.leaderboard-title {
  color: #32c456;
  font-weight: bolder;
  padding: 10px 0px 0px 0px;
  font-size: calc(10px + 2vmin);
}
.leaderboard-title2 {
  color: #32c456;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}

.leaderboard-page-text {
  display: inline;
  color: #32c456;
  vertical-align: middle;
  text-align: center;
  font-size: calc(8px + 2vmin);
  padding: 0px 10px 0px 15px;
}

.leaderboard-control-container {
  color: #32c456;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  padding: 5px 0px 5px 0px;
}

.leaderboard-header-title {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-right-color: #32c456;
  border-right-width: 1px;
  border-right-style: dashed;

  font-size: calc(6px + 1vmin);
}
.leaderboard-header-title-end {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(6px + 1vmin);
}

.leaderboard-cell {
  font-weight: normal;
  border-right-color: #32c45688;
  border-right-width: 1px;
  border-right-style: dashed;
  border-bottom-color: #32c45688;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(3px + 1vmin);
}

.leaderboard-cell-end {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c45688;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(3px + 1vmin);
}

.leaderboard-cell-line-end {
  color: #32c456;
  font-weight: normal;
  border-right-color: #32c45688;
  border-right-width: 1px;
  border-right-style: dashed;
  font-size: calc(3px + 1vmin);
}

.leaderboard-cell-end-line-end {
  color: #32c456;
  font-weight: normal;
  font-size: calc(3px + 1vmin);
}

.leaderboard-tbody {
  display: block;
  height: auto;
  min-height: 40px;
  overflow-y: auto;
}

.leaderboard-footer {
  color: #32c456;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
  font-size: calc(3px + 1vmin);
  font-style: italic;
  padding: 5px 20px 10px 20px;
}
@media (max-width: 767px) and (orientation: portrait) {
  .leaderboard-tbody {
    max-height: calc(0.35 * 100vw);
  }
  .leaderboard-cell, .leaderboard-cell-end,
  .leaderboard-cell-line-end, .leaderboard-cell-end-line-end {
    font-size: calc(8px + 1vmin);
  }
  .leaderboard-header-title , .leaderboard-header-title-end {
    font-size: calc(8px + 1vmin);
  }
  .leaderboard-footer {
    font-size: calc(4px + 1vmin);
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .leaderboard-tbody {
    max-height: calc(0.2 * 100vw);
  }
  .leaderboard-cell, .leaderboard-cell-end,
  .leaderboard-cell-line-end, .leaderboard-cell-end-line-end {
    font-size: calc(7px + 1vmin);
  }
  .leaderboard-header-title , .leaderboard-header-title-end {
    font-size: calc(7px + 1vmin);
  }
  .leaderboard-footer {
    font-size: calc(3px + 1vmin);
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  .leaderboard-tbody {
    max-height: calc(0.25 * 100vw);
  }
  .leaderboard-cell, .leaderboard-cell-end,
  .leaderboard-cell-line-end, .leaderboard-cell-end-line-end {
    font-size: calc(8px + 1vmin);
  }
  .leaderboard-header-title , .leaderboard-header-title-end {
    font-size: calc(8px + 1vmin);
  }
  .leaderboard-footer {
    font-size: calc(4px + 1vmin);
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .leaderboard-tbody {
    max-height: calc(0.125 * 100vw);
  }
  .leaderboard-cell, .leaderboard-cell-end,
  .leaderboard-cell-line-end, .leaderboard-cell-end-line-end {
    font-size: calc(4px + 1vmin);
  }
  .leaderboard-header-title , .leaderboard-header-title-end {
    font-size: calc(4px + 1vmin);
  }
  .leaderboard-title {
    font-size: calc(10px + 1vmin);
  }
  .leaderboard-page-text {
    font-size: calc(8px + 1vmin);
  }
  .leaderboard-footer {
    font-size: calc(4px + 1vmin);
  }
}


.leaderboard-tbody::-webkit-scrollbar {
  width: 0px; /* Largeur de la barre de défilement */
  background-color: #000000; /* Couleur de fond de la barre de défilement */
}

.leaderboard-tbody::-webkit-scrollbar-thumb {
  background-color: #32c45688; /* Couleur du curseur de la barre de défilement */
  border-radius: 6px; /* Bordure arrondie du curseur */
}

.leaderboard-tbody::-webkit-scrollbar-thumb:hover {
  background-color: #32c456; /* Couleur du curseur au survol */
}

.leaderboard-thead,
.leaderboard-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}

.leaderboard-thead {
  width: 100%;
  height: 30px;
}
.leaderboard-table {
  width: 100%;
  height: auto;
}

.leaderboard-button {
  background-color: #000000;
  border: none;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-left: 10px;
}

.leaderboard-button-image {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
