/* src/component/gameCombo/GameCombo.css */
.gameCombo {
  display: flex;
  width: 90vw;
  flex-direction: column;
  padding-bottom: 30px;
  padding-right: 0px;
}

@media (min-width: 768px) and (orientation: portrait) {
  .gameCombo {
    padding-right: 0px;
    width: 90vw;
  }
}
@media (min-width: 1024px) and (orientation: landscape) {
  .gameCombo {
    padding-right: 135px;
  }
}
.gameCombo-title {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: calc(10px + 2vmin);
  text-align: left;
  text-decoration: underline;
  color: #32c456;
}

ul {
  margin: 0;
  padding: 0;
}

.gameCombo-list {
  list-style-type: disc;
  text-align: left;
}

.gameCombo-list li {
  margin-bottom: 5px;
  font-size: calc(5px + 2vmin);
  color: #32c456;
}
