/* src/component/rFactor/RFactor.css */
.rFactor-panel-text {
  position: fixed;
  top: 50px;
  right: 10px;
  width: 45px;
  flex-direction: row;
  background-color: #000000;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px dashed #32c456;
  z-index: 5;
  color: #32c456;
  cursor: pointer;
}

.rFactor-title {
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  text-decoration: underline;
}

.rfactor-label {
  text-align: center;
}
