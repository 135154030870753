.howToPlay-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: calc(10px + 2vmin);
  text-align: left;
  width: 100%;
  text-decoration: underline;
  color: #32c456;
}

.howToPlay-title2 {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: calc(7px + 2vmin);
  text-align: center;
  color: #ffff00;
  width: 90vw;
}

.howToPlay-subtitle {
  margin-bottom: 20px;
  font-size: calc(7px + 2vmin);
  text-align: center;
  color: #32c456;
}
.panel-howToPlay {
  width: 90vw;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.panel-howToPlay-case {
  flex: 1;
  text-align: center;
  margin-bottom: 10px;
}

.panel-howToPlay-image {
  width: 40%;
  margin-bottom: 5px;
}

/* Styles pour les grands écrans en orientation paysage */
@media (max-width: 767px) and (orientation: portrait) {
  .panel-howToPlay {
    flex-direction: column;
  }
  .panel-howToPlay-case {
    margin-bottom: 0px;
  }
  .panel-howToPlay-image {
    width: 20vw;
  }
  .howToPlay-title2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .panel-howToPlay {
    width: 90vw;
  }
  .howToPlay-subtitle {
    height: 40px;
  }
  .howToPlay-title2 {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (orientation: portrait) {
  .howToPlay-title2 {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) and (orientation: landscape) {
  .panel-howToPlay {
    width: 90vw;
  }
  .howToPlay-subtitle {
    height: 40px;
  }
  .howToPlay-title2 {
    margin-bottom: 50px;
  }
}
