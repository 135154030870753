/* src/component/privacyText/PrivacyText.css */
.title {
    padding-top: 70px;
    width: 100vw;
  }
  
  .title-label {
    font-size: calc(30px + 2vmin);
    font-weight: bolder;
    color: #32c456;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    text-shadow: 0px 0px 5px #32c456;
  }
  .text {
    font-size: calc(15px + 2vmin);
    color: #32c456;
    padding-left: 30px;
    padding-right: 30px;
  }
  .textPrivacy {
    font-size: calc(4px + 2vmin);
    color: #32c456;
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
  }
  .textPrivacyList {
    font-size: calc(4px + 2vmin);
    color: #32c456;
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
  }
  .textPrivacyTitle {
    font-size: calc(10px + 2vmin);
    color: #32c456;
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
    font-weight: bold;
  }
  .textPrivacySubTitle {
    font-size: calc(8px + 2vmin);
    color: #32c456;
    padding-left: 40px;
    padding-right: 40px;
    text-align: start;
    font-weight: bold;
  }
  