/* src/component/infectedCountByCountry/InfectedCountByCountry.css */
.infectedCountByCountry-container {
  width: 100%;
  color: #32c456;
  margin: auto;
}

.infectedCountByCountry-content {
  width: 100%;
  color: #32c456;
  border-color: #32c456;
  border-width: 1px;
  border-style: dashed;
  align-self: center;
  margin: auto;
}

/* @media (max-width: 767px) and (orientation: portrait) {
  .infectedCountByCountry-container {
    display: none;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .infectedCountByCountry-container {
    display: none;
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  .infectedCountByCountry-container {
    display: none;
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .infectedCountByCountry-container {
    width: 27%;
  }
} */

.infectedCountByCountry-title {
  color: #32c456;
  font-weight: bolder;
  padding: 10px 0px 10px 0px;
  font-size: calc(10px + 2vmin);
  border-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}
.infectedCountByCountry-title2 {
  color: #32c456;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
}s

.infectedCountByCountry-footer {
  color: #32c456;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
  font-size: calc(8px + 1vmin);
  font-style: italic;
  padding: 5px 20px 10px 20px;
}

.infectedCountByCountry-header-title {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-right-color: #32c456;
  border-right-width: 1px;
  border-right-style: dashed;

  font-size: calc(6px + 1vmin);
}
.infectedCountByCountry-header-title-end {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c456;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(6px + 1vmin);
}

.infectedCountByCountry-cell {
  color: #32c456;
  font-weight: normal;
  border-right-color: #32c45688;
  border-right-width: 1px;
  border-right-style: dashed;
  border-bottom-color: #32c45688;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(3px + 1vmin);
}

.infectedCountByCountry-cell-end {
  color: #32c456;
  font-weight: normal;
  border-bottom-color: #32c45688;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  font-size: calc(3px + 1vmin);
}
.infectedCountByCountry-cell-line-end {
  color: #32c456;
  font-weight: normal;
  border-right-color: #32c45688;
  border-right-width: 1px;
  border-right-style: dashed;
  font-size: calc(3px + 1vmin);
}

.infectedCountByCountry-cell-end-line-end {
  color: #32c456;
  font-weight: normal;
  font-size: calc(3px + 1vmin);
}

.infectedCountByCountry-tbody {
  display: block;
  height: auto;
  min-height: 40px;
  overflow: auto;
}

@media (max-width: 767px) and (orientation: portrait) {
  .infectedCountByCountry-tbody {
    max-height: calc(0.35 * 100vw);
  }
  
  .infectedCountByCountry-cell, .infectedCountByCountry-cell-end,
  .infectedCountByCountry-cell-line-end, .infectedCountByCountry-cell-end-line-end {
    font-size: calc(8px + 1vmin);
  }
  .infectedCountByCountry-header-title , .infectedCountByCountry-header-title-end {
    font-size: calc(8px + 1vmin);
  }
  .infectedCountByCountry-footer {
    font-size: calc(4px + 1vmin);
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .infectedCountByCountry-tbody {
    max-height: calc(0.2 * 100vw);
  }
  
  .infectedCountByCountry-cell, .infectedCountByCountry-cell-end,
  .infectedCountByCountry-cell-line-end, .infectedCountByCountry-cell-end-line-end {
    font-size: calc(10px + 1vmin);
  }
  .infectedCountByCountry-header-title , .infectedCountByCountry-header-title-end {
    font-size: calc(10px + 1vmin);
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  .infectedCountByCountry-tbody {
    max-height: calc(0.25 * 100vw);
  } 
  .infectedCountByCountry-cell, .infectedCountByCountry-cell-end,
  .infectedCountByCountry-cell-line-end, .infectedCountByCountry-cell-end-line-end {
    font-size: calc(8px + 1vmin);
  }
  .infectedCountByCountry-header-title , .infectedCountByCountry-header-title-end {
    font-size: calc(8px + 1vmin);
  }
  .infectedCountByCountry-footer {
    font-size: calc(4px + 1vmin);
  }
}
@media (min-width: 768px) and (orientation: landscape) {
  .infectedCountByCountry-tbody {
    max-height: calc(0.125 * 100vw);
  }
  .infectedCountByCountry-cell, .infectedCountByCountry-cell-end,
  .infectedCountByCountry-cell-line-end, .infectedCountByCountry-cell-end-line-end {
    font-size: calc(4px + 1vmin);
  }
  .infectedCountByCountry-header-title , .infectedCountByCountry-header-title-end {
    font-size: calc(4px + 1vmin);
  }
  .infectedCountByCountry-title {
    font-size: calc(10px + 1vmin);
  }
  .infectedCountByCountry-footer {
    font-size: calc(2px + 1vmin);
  }
}

.infectedCountByCountry-tbody::-webkit-scrollbar {
  width: 0px; /* Largeur de la barre de défilement */
  background-color: #000000; /* Couleur de fond de la barre de défilement */
}

.infectedCountByCountry-tbody::-webkit-scrollbar-thumb {
  background-color: #32c45688; /* Couleur du curseur de la barre de défilement */
  border-radius: 6px; /* Bordure arrondie du curseur */
}

.infectedCountByCountry-tbody::-webkit-scrollbar-thumb:hover {
  background-color: #32c456; /* Couleur du curseur au survol */
}

.infectedCountByCountry-thead,
.infectedCountByCountry-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
.infectedCountByCountry-thead {
  width: 100%;
  height: 30px;
}

.infectedCountByCountry-table {
  width: 100%;
  height: auto;
}
