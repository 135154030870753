/* src/component/overlayQrCode/OverlayQrCode.css */
.container {
  position: fixed;
  bottom: 40px;
  right: 15px;
  width: 110px;
  border: 1px solid #32c456;
  background-color: #000000;
  z-index: 1;
  box-shadow: 0px 0px 10px #ffff00;
}

.fixed-image {
  width: 110px;
  height: 110px;
}

.text-qrcode-container {
  display: flex;
  margin: 0px;
  margin: 5px;
  margin-top:0px;
  height: 25px;
  align-items: center;
  justify-content: center;
}

.text-qrcode {
  color: #32c456;
  font-size: 10px;
  font-weight: bolder;
  text-align: center;
}

@media (max-width: 1023px) {
  .container {
    display: none;
  }
}
