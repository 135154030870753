/* src/component/theHorde/TheHorde.css */
.theHorde {
  display: flex;
  width: 90vw;
  flex-direction: column;
  justify-content: left;
  padding-bottom: 30px;
  padding-right: 10px;
  color: #32c456;
}
/* Styles pour les petits écrans en orientation portrait */
@media (max-width: 767px) and (orientation: portrait) {
  .theHorde {
    padding-right: 30px;
    width: 90vw;
  }
}

.theHorde-title {
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
}
.theHorde-subtitle {
  font-size: calc(6px + 2vmin);
  text-align: left;
}

ul {
  margin: 0;
  padding: 0;
}

.theHorde-list {
  list-style-type: disc; /* Utilisation de puces rondes */
  margin-left: 20px; /* Marge à gauche pour l'indentation */
  text-align: left;
}
.theHorde-list li {
  margin-bottom: 5px; /* Espacement entre les éléments */
  font-size: calc(6px + 2vmin);
}

.theHorde-discord-link {
  font-size: calc(6px + 2vmin);
  text-decoration: underline;
  color: #32c456;
}
