/* src/component/disclaimer/Disclaimer.css */
.disclaimer-title {
  font-weight: bold;
  font-size: calc(6px + 2vmin);
  text-align: left;
  text-decoration: underline;
  padding-left: 5px;
}

ul {
  margin: 0;
  padding: 0;
}

.disclaimer-list {
  list-style-type: disc;
  margin-left: 20px;
  text-align: left;
}

.disclaimer-list li {
  margin-bottom: 5px;
  font-size: calc(8px + 1vmin);
}

.toggle-button {
  background-color: #000000;
  border: none;
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-top: 5px;
  margin-right: 2px;
}
.close-button {
  position: absolute;
  top: 8px;
  right: 6px;
  background-color: #000000;
  border: none;
  cursor: pointer;
}
.toggle-button-image {
  width: 18px;
  height: 18px;
}
.close-button-image {
  width: 10px;
  height: 10px;
}

.panel-text {
  position: fixed;
  bottom: 50px;
  left: 10px;
  width: 75vw;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  border: 1px dashed #32c456;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  color: #32c456
}
