/* src/component/loading/Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  align-content: center;
  justify-content: center;
}

.loading-label {
  text-align: center;
  color: #32c456;
  font-size: calc(10px + 2vmin);
  font-family: "Fixed Width", monospace;
}

.loading-bar {
  width: 50vw;
  height: 10px;
  align-self: center;
  background-color: #f0f0f0;
}

.loading-progress {
  height: 100%;
  background-color: #32c456;
  box-shadow: 0px 0px 15px #32c456;
}

.loading-logo {
  align-self: center;
  margin-top: 20px;
  pointer-events: none;
  animation-name: loading-logo-spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 36px;
  height: 36px;
}

@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
